import styled from "styled-components";

export const RobotImg = styled.img`
    max-width:70%;
    max-height:90%;
    min-height:60%;
    position:absolute; 
    right:0% ;
    bottom:0%;
`
export const StyledButton = styled.button`    
    height:30%;
    width:50%;
    font-size:5vh;
    margin-top:10vh;
    margin-left:25%;
    border:none;
    color:black;
    background-color:white;
    cursor:pointer;
`