import React from "react";
import StartNav from "./HomeComponents/HomeNav/HomeNav";
import { Icon } from "../../components/HomeBack/Icon";
import { Letters } from "./HomeComponents/Type/Letters";
import { ChabotsLetters } from "./HomeComponents/ChabotsLetters/ChabotsLetters";
import { MakersRobot } from "./HomeComponents/MakersRobot/makersRobot";


export default function Home  () {
    return(
        <>

            <div style={{height:'100vmin', width:"100%"}}>
                <StartNav/>
                <div style={{width:"100%", height:"100%", display:'flex',justifyContent:'space-between'}}>
                    <Letters/>
                    <Icon />
                    <ChabotsLetters/>
                </div>
            </div>       
            <MakersRobot/>



        </>
    )
}
