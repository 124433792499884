import styled from "styled-components";

export const Head = styled.h1`
    font-size:10vh;
`

export const ImgDisplay = styled.img`
    width:100%;
    max-height:60vh;
`

export const ImgH1 = styled.h1`
    text-align:center;
`

export const GridContainer = styled.div`
  max-width:100%;
  height:37.5vh;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  background-color: #000000;
  padding: 10px;
  `

  export const Textos = styled.h1`
    text-align:center;
    color: white;
  `