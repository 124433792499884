import styled from "styled-components";
import React from "react";
import {motion } from "framer-motion"
import { NavButton, StyledNav } from "./HomeNavStyles";


const StartNav = () => {
    return(
        <>
        <div style={{ width:"100%", display:'flex',justifyContent:'flex-end'}}>
            <StyledNav>
                <NavButton ><a href="/about">ABOUT US</a></NavButton>
                <NavButton ><a href="/history">EXPERIENCE</a></NavButton>
                <NavButton ><a>CONTACT US</a></NavButton>
                <NavButton style={{marginRight:'10vh', color:'white',backgroundColor:'black',}}><a>COLABORATE</a></NavButton>
            </StyledNav>
        </div>
        </>
    )
}
export default StartNav;