import React from "react";
import StartNav from "../Home/HomeComponents/HomeNav/HomeNav";
import { H1 } from "./historystyles";
import { HistoryFirst } from "./historycomponents/firstpart/firstpart";
import { Images } from "./historystyles";
import SecondPart from "./historycomponents/secondpart/secondpart";


const History  = () =>{
    return(
        <>

            <div style={{height:'100vmin', width:"100%"}}>
                <StartNav/>
                <div style={{width:"100%", height:"30%", display:'flex',justifyContent:'space-between', marginTop:"20vh"}}>
                    <H1 style={{fontSize:"7vw"}}>about us</H1>
                    <H1 style={{textAlign:"right"}}>descubre mas sobre la <br/> divulgacion de la robotica en <br /> mexico</H1>
                </div>
                <HistoryFirst/>
                <div style={{height:"30%", display:"flex",  marginRight:"10%", marginLeft:"10%", marginTop:"5%", marginBottom:"5%"}}>
                    <h1 style={{textAlign: "center", fontSize:"40px", }}>“Aprende y comparte tu conocimiento Muéstrale al mundo tu 
                    forma de pensar e innovar,Muéstrale al mundo tu Robotista interior grita hasta que los engranes giren.”</h1>
                </div>
                <div style={{height:"200vh", backgroundColor:"black"}}>
                    <SecondPart/>
                </div>
            </div>       
  



        </>
    )
}

export default History;