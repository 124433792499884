import React from "react";
import Home from './Pages/Home/Home';
import {RouterProvider, createBrowserRouter } from 'react-router-dom';
import Philosophy from "./Pages/philosophy/philosophy";
import History from "./Pages/History/history";

const router = createBrowserRouter([
  {
  path: "/",
  element: <Home />,
  },
  {
    path: "/about",
    element: <Philosophy />,
    }  ,
    {
        path: "/history",
        element: <History />,
        } 
]);

export const App = () => {
    return(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>    


    )
}

