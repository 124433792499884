import React from "react";
import Originals from '../../../../images/originals.png'

export const HistoryFirst = () => {
    return(
        <>
        <div style={{height:"10vmin", width:"100%", backgroundColor:"black", display:'flex'}}>   
                <div style={{height:"50%",width:"50%", backgroundColor:"white", position:"absolute", right:"0%",zIndex:"100", top:" 55%" }}>   
                <img src={Originals} style={{display:"flex", position:"absolute", width:"95%", marginLeft:"2.5%", top:"-5%"}}></img>
                </div>
                <div style={{width:"40%", marginLeft:"5%", marginTop:"5%"}}>
                <h1 style={{color:"white", textAlign:"center"}}>chabots nace en 2015 con la idea de hacer amigos,
                de hacer robots, de ir a concursos 
                y saber hacer eso que tanto te gusta</h1> 
                </div>
        </div>
        <div style={{height:"40vmin", width:"100%", backgroundColor:"black", display:'flex'}}>   

        </div>
        </>

    )
}