import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledNav = styled(motion.nav)`
    justify-content: space-between;
    display: flex;
    align-items: center;
    a{
        text-decoration:none;
    }
`
export const NavButton = styled(motion.button) `
    background-color: #ffffff; /* Green */
    border: none;
    color: #000000;
    padding: 1.5vh;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 3vh;
    margin: 2vh 3vh;
    transition-duration: 0.4s;
    cursor: pointer;
`