import React from   'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

const appear = {
    hidden: {opacity: 0},
    visible: {
        opacity: 1,
        transition: {
          opacity: { delay:1, duration: 0 },

        }
      }
    }
const appear1 = {
    hidden: {opacity: 0},
    visible: {
        opacity: 1,
        transition: {   
            opacity: { delay:1.75  , duration: 0 },

        }
        }
    }
const appear2 = {
    hidden: {opacity: 0},
    visible: {
        opacity: 1,
        transition: {
            opacity: { delay:2.5, duration: 0 },

        }
        }
    }
const appear3 = {
    hidden: {opacity: 0},
    visible: {
        opacity: 1,
        transition: {
            opacity: { delay:3.25, duration: 0 },

        }
        }
    }

const typing = keyframes `
from { width: 0 }
to { width: 100% }  `
  
  /* The typewriter cursor effect */
const blink = keyframes `
    from, to { border-color: transparent }
    50% { border-color: BLACK; }
  `


  



const Type1 = styled(motion.h1)`
    font-size:5vw;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid white; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */

    animation:${blink} .3s 1s step-end 0.9, ${typing} 0.75s 1s  steps(9, end) ;


`
const Type2 = styled(motion.h1)`
    font-size: 5vw;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid white; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */

    animation:${blink} .5s 1.75s step-end 2.5, ${typing} 1s 1.75s  steps(15, end) ;



`
const Type3 = styled(motion.h1)`
    font-size:5vw;
    font-family: murasuit;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid white; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */

    animation:${blink} .5s 2.5s step-end 2.5, ${typing} 1s 2.5s  steps(15, end) ;

`
const Type4 = styled(motion.h1)`
    font-size:5vw;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid white; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */

    animation:${blink} .5s 3.25s step-end 4, ${typing} 1s 3.25s  steps(15, end) ;


`
const LettersDiv = styled.div`

    width:min-content;
    margin-top:0vh;
    margin-left:5vw;
`
export const Letters = ()=>{
    return(
        <>           
            <div style={{paddingTop:"2vh"}}> 
                <LettersDiv style={{marginTop:"10vh"}}><Type1
                initial="hidden"
                animate="visible"
                variants={appear} > EAT </Type1></LettersDiv>
                <LettersDiv><Type2
                initial="hidden"
                animate="visible"
                variants={appear1} >  &nbsp;SLEEP </Type2></LettersDiv>
                <LettersDiv><Type3
                initial="hidden"
                animate="visible"
                variants={appear2} >  &nbsp;ROBOTS </Type3></LettersDiv>
                 <LettersDiv><Type4
                initial="hidden"
                animate="visible"
                variants={appear3} > REPEAT </Type4></LettersDiv>
            </div>
        </>
    )
}

  