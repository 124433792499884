import React from "react";
import { TextHistory } from "./secondpartstyles";
import Cha2017 from "../../../../images/chabots2017.png";

const SecondPart = () => {
    return(
        <>
        <div style={{display:"flex"}}>
            <div style={{backgroundColor:"white", width:"50vw", height:"50vh"}}>
                <img src={Cha2017} style={{height:"95%", marginLeft:"5%"}}/>
            </div>
            <div style={{width:"30vw"}}>
                <TextHistory>Eventualmente mas gente se unió al proyecto, el buen desempeño y disciplina logró rápidamente que chaBots destacara y comenzará a ser reconocido como un equipo serio </TextHistory>
            </div>
        </div>
        </>
    )
}

export default SecondPart;