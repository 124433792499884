import React from 'react';
import { motion } from 'framer-motion';


const draw = {
    hidden: { pathLength: 0, opacity: 0 , fill: 'rgba(0, 192, 255,0)'},
    visible: {
        pathLength: 1.1,
        opacity: 1,
        fill: '#000000',
        transition: {
          pathLength: { delay:4, type: "spring", duration: 4 , bounce: 0 },
          opacity: { delay:4, duration: 1 },
          fill:{delay:5.5}
        }
      }
    }
    
const drawCircle = {
    hidden: { pathLength: 0, opacity: 0 , fill: 'rgba(0, 192, 255,0)'},
    visible: {
        pathLength: 1.1,
        opacity: 1,
        fill: '#000000',
        transition: {
          pathLength: { delay:4, type: "spring", duration: 1.5, bounce: 0 },
          opacity: { delay:4, duration: 1 },
          fill:{delay:5.5}
        }
      }
    }

    
export const Icon = () => {
    return(
        <>
          <div  style={{marginTop:'5%', marginRight:"10%",height:"50%", width:"30%", display:'flex'  }}>
            <motion.svg 
            viewBox='0 0 1500 1500'
            width="1500px" 
            height="auto" 
            preserveAspectRatio="xMaxYMax" x="0" y="0" 
            initial="hidden"
            animate="visible"
            >
            <motion.rect
            x="700"
            y="100"
            width="100"
            height="400"
            stroke="rgb(0, 0, 0)"
            variants={draw}
            custom={1}
            
            />
            <motion.polygon
            points="0, 500, 1500,500 ,1500,750,1400,750,1400,600,90,600,90,750,0,750"
            stroke="rgb(0, 0, 0)"
            variants={draw}
            custom={1}
            />
            <motion.polygon
            points="0, 1250, 00, 1500, 1500,
            1500,1500,1250,1400,1250,1400,1400,100,1400,100,1250"
            stroke="rgb(0, 0, 0)"
            variants={draw}
            custom={1}
            />
            <motion.circle cx="400" cy="1150" r="100"
            stroke="rgb(0, 0, 0)"
            variants={drawCircle}
            custom={1}
            />
            <motion.circle cx="1100" cy="1150" r="100"
            stroke="rgb(0, 0, 0)"
            variants={drawCircle}
            custom={1}    />

        </motion.svg>
        </div>
        </>
    )
}
