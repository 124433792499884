import React from "react";
import StartNav from "../Home/HomeComponents/HomeNav/HomeNav";
import { GridContainer, Head, ImgDisplay, ImgH1, Textos } from "./philosophystyles";
import limur from "../../images/limur.jpg";
import isaac from "../../images/isaac.jpg";
import turing from "../../images/turing.jpg";
import all from "../../images/all.jpg";
import { ChabotsLetters } from "../Home/HomeComponents/ChabotsLetters/ChabotsLetters";


function Philosophy () {
    return(
        <>
        <StartNav/>
        <div>
            <div style={{width:"100%",display:"flex", justifyContent:"center", marginTop:"5vh  " }}>
                <Head>Our Philosopy</Head>
            </div>
            <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                <h2 style={{width:"80%", marginBottom:"10vh"}}>En chaBots uno de nuestros objetivos es difundir la robótica en nuestro país y solemos hacerlo mediante actividades como conferencias, clases y concursos porque nos gusta inspirar, transmitir lo que algún día alguien nos transmitió </h2>
            </div>
            
            <div style={{width:"100%",display:'flex', justifyContent:"space-between"}}>
                <div style={{marginLeft:"10%"}}>
                <ImgDisplay src={turing} />
                <ImgH1>Alan Turing</ImgH1>
                </div>
                <div>
                <ImgDisplay src={limur} alt=""/>
                <ImgH1>Limur Fried</ImgH1>
                </div>
                <div style={{marginRight:"0%"}}>
                <ImgDisplay src={isaac}  />
                <ImgH1>Isaac Asimov</ImgH1>
                </div>
                <ChabotsLetters style={{margin:"0", marginLeft:"30vh"}}/>
            </div>
            <div style={{width:"100%", height:"37.5vh",display:'flex', backgroundColor:"black", justifyContent:"center"}}>
                <h2 style={{color:"white", textAlign:"center", width:"70%", marginTop:"10vh"}}>Todos tenemos una historia, el como un día llegamos a preguntarnos como funcionaba algo, -¿yo podré hacerlo? 
¿esto funcionará?-¿aquello para que sirve? 
nosotros buscamos saciar nuestra curiosidad a toda costa lo que nos llevo al hambre de conocimiento, Aquí algunas pequeñas historias de como llegamos aquí y quien, cuando y donde se cambió nuestra vida. </h2>
                
            </div>
            <GridContainer>
                <Textos>-Elim</Textos>
                <Textos>-Andres</Textos>
                <Textos>-Python</Textos>
                <Textos>-Erick</Textos>
                <Textos>-Daniel Khun</Textos>
                <Textos>-Alan</Textos>
                <Textos>-Rodrigo</Textos>
                <Textos>-Alejandro  </Textos>
                <Textos>-Roy</Textos>
                <Textos>-Hugo</Textos>
                <Textos>-Toño</Textos>
                <Textos>-Luna</Textos>
                <Textos>-Hiram</Textos> 
                <Textos>-Leo</Textos>
                

                </GridContainer>
             </div>
             <div style={{width:'100%', height:"70vh",display:"flex"}}>
                <div style={{height:"100%", width:"50%", marginTop:"5vh", marginLeft:"2vw"}}>
                    <img src={all} alt="chabots" style={{width:"100%"}}/>
                </div>
                <div style={{width:"30%", margin:"5%"}}>
                <h1>Aquí no hay edades, no hay géneros ni barreras que nos impidan hacer lo que mas nos gusta, la robótica nos une y damos lo mejor de nosotros, el compañerismo solidaridad nos rigen.</h1>
                </div>
             </div>

        </>
    )
}

export default Philosophy;