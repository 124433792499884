
import robotImg from '../../../../images/robot-start.png'
import React from "react";
import { RobotImg, StyledButton } from './makersstyled';

export const MakersRobot = () => {
    return(
        <>
        <div style={{height:"70vmin", width:"100%", backgroundColor:"black", display:'flex'}}>   
                <div style={{height:"70%",width:"80%", backgroundColor:"white", position:"absolute", right:"0%",zIndex:"100", top:"90%" }}>   
                    <h1 style={{textAlign:"right"}}>Descubre mas sobre la divulgacion de la <br/>robotica en Mexico  </h1>      
                    <h1 style={{fontSize:"10vh", textAlign: "right", width:"28%", position:"absolute", bottom:"20%"}}>  
                        Makers  <br/>Gonna<br/> Make
                    </h1>
                    <RobotImg src={robotImg} alt="robot" />
                </div>
        </div>
        <div style={{height:"40vmin", width:"100%", backgroundColor:"black", display:'flex'}}>   
            <StyledButton>aprende mas de nuestra filosofia</StyledButton>
        </div>
        </>

    )
}